import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/reports";

export const createFirstReport = async(firstReportForm: Learnlink.Report.First): Promise<Learnlink.Report.default> => {
  const res = await learnlinkAPI.axios.post<Learnlink.Report.default>(`${ENDPOINT}/first`, firstReportForm);
  return res.data;
};

export const createPeriodicalReport = async(periodicalReportForm: Learnlink.Report.Periodical): Promise<Learnlink.Report.default> => {
  const res = await learnlinkAPI.axios.post<Learnlink.Report.default>(`${ENDPOINT}/periodical`, periodicalReportForm);
  return res.data;
};

export const createReport = async(reportForm: Learnlink.Report.Regular): Promise<Learnlink.Report.default> => {
  const res = await learnlinkAPI.axios.post<Learnlink.Report.default>(`${ENDPOINT}/`, reportForm);
  return res.data;
};

export const getAllReportsForUser = async(userUID: string, isSeller: boolean): Promise<Learnlink.Report.FullViewApp[]> => {
  const subPath = isSeller ? "seller" : "customer";
  const res = await learnlinkAPI.axios.get<Learnlink.Report.FullViewApp[]>(`${ENDPOINT}/${subPath}/${userUID}`);
  return res.data;
};

export const getAllReportsForProjectByType = async(reportType: Learnlink.Report.Type, projectID: string): Promise<Learnlink.Report.default[]> => {
  const res = await learnlinkAPI.axios.get<Learnlink.Report.default[]>(`${ENDPOINT}/byType/${reportType}/${projectID}`);
  return res.data;
};

export const getPreviousReport = async(projectID: string): Promise<Learnlink.Report.default> => {
  const res = await learnlinkAPI.axios.get<Learnlink.Report.default>(`${ENDPOINT}/mountExisting/${projectID}`);
  return res.data;
};
