













































































































































































import { createReport, getPreviousReport } from "@/api/reportsService";
import RateLesson from "@/components/lessons/RateLesson.vue";
import handleError from "@/helpers/errors";
import formRules from "@/helpers/formRules";
import { placeholderTexts } from "@/helpers/reportFormUtils";
import { useNotifier } from "@/providers/notifier";
import { uiModule } from "@/store/modules/ui";
import { defineComponent, onMounted, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import Vue from "vue";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "RegularReportForm",
  components: { RateLesson },
  props: {
    lesson: {
      type: Object as PropType<Learnlink.Lesson.FullViewApp>,
      required: true,
    },
    studentName: {
      type: String as PropType<string>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, {
    emit,
    refs,
  }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });
    const vm = reactive({
      loading: false,
      reportForm: {
        customerUID: props.lesson.customerUID,
        goalLater: "",
        goalToday: "",
        lessonID: props.lesson.ID,
        motivation: 50,
        motivationText: "",
        name: props.studentName,
        nextTime: "",
        notes: "",
        projectID: props.lesson.projectID,
        sellerUID: props.lesson.sellerUID || "",
      },
    });
    const validForm = ref(false);
    const notify = useNotifier();

    function closeForm() {
      _value.value = false;
      emit("success");
    }

    onMounted(async() => {
      vm.loading = true;
      const previousReport = await getPreviousReport(props.lesson.projectID);
      if (previousReport?.goalLater) vm.reportForm.goalLater = previousReport.goalLater;
      vm.loading = false;
    });
    const submitReport = async() => {
      vm.loading = true;
      (refs.form as Vue & { validate: () => boolean; }).validate();
      if (validForm.value) {
        try {
          await createReport(vm.reportForm);
          uiModule.actions.fetchIncompleteReportsCount();
          notify({
            title: "Sendt 🥳",
            message: "Rapporten ble sendt uten problemer.",
            type: "success",
          });
          closeForm();
        }
        catch (e) {
          notify({
            title: "Klarte ikke sende Rapporten 🤯",
            message: "Kontakt support hvis problemet vedvarer.",
            type: "error",
          });
          handleError(e);
        }
      }
      else {
        notify({
          title: "Noe mangler! 📋",
          message: "Du trenger bare å fylle ut ett av feltene. Klarer du å finne ut hvilket? 😉",
          type: "error",
        });
      }
      vm.loading = false;
    };
    return {
      _value,
      closeForm,
      formRules,
      placeholderTexts,
      submitReport,
      validForm,
      vm,
    };
  },
});
